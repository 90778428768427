import React from 'react';
import DealReversalForm from '../../../components/DealReversalForm';
import {InitialDealReversalFormValues} from '../../../types';

export const initialFormValues: InitialDealReversalFormValues = {
  createNewDeal: undefined,
  createNegativeInvoice: undefined,
  comments: undefined,
  reversalDealId: undefined,
  newDealId: undefined,
};

const CreateDealReversal = () => {
  return <DealReversalForm title="Reverse Deal" initialValues={initialFormValues} />;
};

export default CreateDealReversal;
