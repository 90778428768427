import React, {FC, useContext} from 'react';
import {AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';
import {useFormikContext, Field, setNestedObjectValues} from 'formik';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import {post, api} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {DealReversalFormBag, DealReversalResponse, DealReversalFormValues} from '../../types';
import {isNullish} from '../../utils';
import InputField from '../Form/InputField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import {Button} from '../Button';
const PREFIX = 'StepDealReversalDetails';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
}));

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  onBack: (values: DealReversalFormValues, bag: DealReversalFormBag) => void;
  onNext: (values: DealReversalFormValues, bag: DealReversalFormBag) => void;
  style?: React.CSSProperties;
};

const StepDealReversalDetails: FC<Props> = ({step, title, totalStep, onBack, onNext}) => {
  const navigate = useNavigate();
  const bag = useFormikContext<DealReversalFormValues>();
  const {setFieldValue, errors, touched, setTouched, validateForm, values} = bag;
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const updateFormValues = (updatedValues: Partial<DealReversalFormValues>) => {
    Object.entries(updatedValues).forEach(([key, value]) => {
      setFieldValue(key, value);
    });
  };

  const handleCreateReversal = async (
    values: DealReversalFormValues,
    onSuccess?: (values: DealReversalFormValues, bag: DealReversalFormBag) => void
  ) => {
    showLoading();
    alertService.clear(defaultAlertId);
    try {
      const response: AxiosResponse<DealReversalResponse> = await post(
        api(Consts.Api.DealReversal),
        values
      );
      updateFormValues({...response.data});
      onSuccess?.({...values, ...response.data}, bag);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleFinishOnlyNavigation = (values: DealReversalFormValues) => {
    if (isNullish(values.newDealId)) {
      // reversal only
      navigate(Consts.RouterPath.DealSummary.replace(':id', values.reversalDealId));
    } else {
      // reversal and new deal
      navigate(Consts.RouterPath.EditDeal.replace(':id', values.newDealId));
    }
  };
  const handleFinaliseOnly = async (values: DealReversalFormValues) => {
    await handleCreateReversal(values, handleFinishOnlyNavigation);
  };

  const handleFinaliseAndAddAttachments = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      await handleCreateReversal(bag.values, onNext);
    } else {
      setTouched(setNestedObjectValues({...bag.initialValues, ...values}, true));
    }
  };

  return (
    <Root className={classes.root}>
      <Stack spacing={6}>
        <FormStep step={step} title={title}>
          <Stack spacing={6}>
            <Stack spacing={0}>
              <Typography variant="body1" gutterBottom>
                What would you like to do?
              </Typography>
              <Typography sx={{fontSize: '0.875rem', fontStyle: 'italic'}}>
                Please note: If you 'Reverse only' you will not be able to link Deals to this group
                later.
              </Typography>
              <Field name="createNewDeal">
                {({field}: any) => (
                  <RadioGroup {...field} value={field.value ?? ''} row>
                    <FormControlLabel value="false" control={<Radio />} label="Reversal Only" />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Reverse & create new deal"
                    />
                  </RadioGroup>
                )}
              </Field>
              {touched.createNewDeal && errors.createNewDeal ? (
                <FormHelperText error>{errors.createNewDeal}</FormHelperText>
              ) : null}
            </Stack>
            <Box>
              <Typography variant="body1" gutterBottom>
                Do you wish to add comments to the reversal? (Optional)
              </Typography>
              <Typography sx={{fontSize: '0.875rem', fontStyle: 'italic'}}>
                eg. Reason for reversal, this will be added to the reversal deal, not the invoice.
              </Typography>
              <InputField
                id="comments"
                name="comments"
                placeholder="Comments"
                fullWidth
                multiline
                minRows={4}
                maxRows={10}
              />
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                Do you wish to send a negative invoice to the Supplier?
              </Typography>
              <Typography sx={{fontSize: '0.875rem', fontStyle: 'italic'}}>
                If you select 'No' the reversal will still be processed but no invoice will be
                generated for the Supplier.
              </Typography>
              <Field name="createNegativeInvoice">
                {({field}: any) => (
                  <RadioGroup {...field} value={field.value ?? ''} row>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                )}
              </Field>
              {touched.createNegativeInvoice && errors.createNegativeInvoice ? (
                <FormHelperText error>{errors.createNegativeInvoice}</FormHelperText>
              ) : null}
            </Box>
          </Stack>
        </FormStep>
        <StepperFormActionSection<DealReversalFormValues>
          handleBack={onBack}
          handleNext={handleFinaliseOnly}
          step={step}
          totalStep={totalStep}
          stepButtonText="Finish"
        >
          <Button variant="contained" type="button" onClick={handleFinaliseAndAddAttachments}>
            Finish & Add Attachments
          </Button>
        </StepperFormActionSection>
      </Stack>
    </Root>
  );
};

export default StepDealReversalDetails;
