import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import {useParams, useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import isValidDate from 'date-fns/isValid';
import {AxiosError, AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {hasRole} from '../../../app/Permission';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode, selectProfileRoles} from '../../../app/selectors';
import {
  AttachmentType,
  AttachmentUploadResponse,
  ClaimListResponse,
  Config,
  DealAgreementSummaryResponse,
  DealValuesForDealSummaryResponse,
  DealValuesForSummaryData,
  EntityActionType,
  Pagination,
} from '../../../types';
import {api, get, post, del} from '../../../utils/Request';
import {getFormattedDateTime} from '../../../utils/DateUtils';
import {getDealType} from '../../../utils/TypesStatusUtils';
import {useFeatureFlag} from '../../../utils/featureFlags';
import {
  ChevronRightIcon,
  CreateDealIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  LocationsIcon,
  MoneyGraphIcon,
  ReverseIcon,
} from '../../../components/Icons';
import ButtonsContainer from '../../../components/Container/ButtonsContainer';
import Button from '../../../components/Button/Button';
import DealSummaryValuesTable from '../../../components/DealForm/DealSummaryValuesTable';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {TableTabPanel} from '../../../components/TableTabPanel';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import {TabsComponent} from '../../../components/TabsComponent';
import {HeadingComponent} from '../../../components/HeadingComponent';
import DynamicContainerWithHeader from '../../../components/Container/DynamicContainerWithHeader';
import FilesUploader, {
  classes as fileUploaderClasses,
} from '../../../components/DragDrop/FilesUploader';
import {DeleteActionButton} from '../../../components/Table';
import {DownloadActionButton} from '../../../components/Table/ActionIconButton';
import Attachment from '../../../components/Attachment/Attachment';
import {
  DeleteAgreementOrRebateConfirmModal,
  DeleteAgreementOrRebateWithActiveClaimConfirmModal,
  DeleteAgreementorRebateWithClaimForbiddenModal,
} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import {OutlinedButton} from '../../../components/Button';
import {ErrorBox} from '../../../components/Alert';
import FinanceAccountsSummary from '../../../components/DealSummary/FinanceAccountsSummary';
import SupplierApprovalNumberEditable from './SupplierApprovalNumberEditable';

const PREFIX = 'DealSummary';

const classes = {
  root: `${PREFIX}-root`,
  pageFlexColumn: `${PREFIX}-pageFlexColumn`,
  flexContent: `${PREFIX}-flexContent`,
  flexContentCentered: `${PREFIX}-flexContentCentered`,
  dealValuesTable: `${PREFIX}-dealValuesTable`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  fixWidthContainerSm: `${PREFIX}-fixWidthContainerSm`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  tabPanelContainerRoot: `${PREFIX}-tabPanelContainerRoot`,
  tabPanelContentWrapper: `${PREFIX}-tabPanelContentWrapper`,
  contentGroupWrapper: `${PREFIX}-contentGroupWrapper`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.pageFlexColumn}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.flexContent}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  [`& .${classes.flexContentCentered}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 2.5rem',
  },

  [`& .${classes.dealValuesTable}`]: {
    width: '100%',
    padding: '0',
  },

  [`& .${classes.contentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 0.5rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
  },

  [`& .${classes.fixWidthContainerSm}`]: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.tabPanelContainerRoot}`]: {
    width: '100%',
    maxWidth: '100%',
    padding: '5.25rem 1.5rem 6.25rem',
  },

  [`& .${classes.tabPanelContentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 5.625rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.contentGroupWrapper}`]: {
    paddingTop: '1.5rem',
  },
}));

const FileUploaderRoot = styled('div')(() => ({
  width: '100%',
  [`& .${fileUploaderClasses.root}`]: {
    border: 'none !important',
    padding: '0 !important',
  },
  [`& .${fileUploaderClasses.headingButton}`]: {
    alignSelf: 'flex-end',
  },
  [`& .${fileUploaderClasses.title}`]: {
    fontSize: '1.625rem !important',
    marginBottom: '0px !important',
  },
  [`& .${fileUploaderClasses.subTitle}`]: {
    fontSize: '1.125rem !important',
    color: '#000 !important',
    marginTop: '0.75rem !important',
  },
  [`& .${fileUploaderClasses.headingContainer}`]: {
    marginBottom: '1.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${fileUploaderClasses.emptyContainer}`]: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
  },
}));

const DownloadButton = styled(Button)`
  width: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 3.625rem;
  background-color: #000000;
  color: #ffffff;
`;
const SummaryReportDownloadButton = styled(OutlinedButton)`
  border-color: black;
  font-size: 16px;
  width: auto;
  height: 3.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;
const UploadButton = styled(Button)`
  height: 3.625rem;
`;
const TabsIndex = {
  DealInformation: 'DealInformation',
  Attachments: 'Attachments',
};
const Tabs = [
  {
    value: TabsIndex.DealInformation,
    label: 'Deal Information',
  },
  {
    value: TabsIndex.Attachments,
    label: 'Attachments',
  },
];

type HeadingButton = {
  label: string;
  icon: React.ReactNode;
  style: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  role: string;
  disabled?: boolean;
  disabledText?: string;
  isLocked?: boolean;
};

type DealDetails = {
  title: string;
  value: string;
};

export const isDealLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

const DealSummary = () => {
  const {id = ''} = useParams<{id: string}>();

  const navigate = useNavigate();
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const profileRoles = useAppSelector(selectProfileRoles);
  const configs = useAppSelector((state) => state.configs.data);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const dealReversalFeatureEnabled = useFeatureFlag(
    Consts.FeatureFlags.EnableDealReversal
  )?.enabled;

  const [selectedTab, setSelectedTab] = useState(TabsIndex.DealInformation);
  const [dealData, setDealData] = useState<DealAgreementSummaryResponse | undefined>();
  const [dealDetails, setDealDetails] = useState<DealDetails[]>([]);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [rows, setRows] = useState<DealValuesForSummaryData[]>([]);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);
  const [openDeleteWithClaimConfirmModal, setOpenDeleteWithClaimConfirmModal] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState<ClaimListResponse | null>(null);
  const [ctrlKey, setCtrlKey] = useState(false);

  const canDeleteAttachment = hasRole(Consts.UserRoleEnum.AddOrUpdateDeals, profileRoles);
  const claimPagination = useMemo(
    () => ({
      ...Consts.DefaultPagination,
      pageSize: Consts.PageSize[0],
    }),
    []
  );
  const isPromo = dealData?.dealValues?.data?.some((d) => (d.promoPrice ?? 0) > 0);

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.DealAttachments.replace(':dealId', id)))
      .then((response: AxiosResponse<AttachmentType[]>) => {
        setAttachments(response.data);
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [id, hideLoading, showLoading]);

  const deleteDeal = () => {
    showLoading();
    del(api(Consts.Api.DealAgreement.replace(':id', id)))
      .then(() => {
        navigate(Consts.RouterPath.MyDeals);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const editDeal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const routerPath = Consts.RouterPath.EditDeal.replace(':id', id);
      if (event.ctrlKey) {
        window.open(routerPath, '_blank');
      } else {
        navigate(routerPath);
      }
    },
    [navigate, id]
  );

  const handleNavAction = useCallback(
    (type: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.persist();
      setCtrlKey(event.ctrlKey);
      if (type === Consts.SummaryPageActionTypes.DUPLICATE) {
        showLoading();
        const requestData = {
          agreementId: id,
          createdByStaffCode: loggedInStaffCode,
        };
        post(api(Consts.Api.DealDuplicate), requestData)
          .then((response) => {
            navigate(Consts.RouterPath.EditDeal.replace(':id', response.data.id));
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.EDIT) {
        showLoading();
        get(api(Consts.Api.DealAgreementClaims.replace(':id', id)), {
          params: {
            status: Consts.ClaimStatusEnum.Raised,
          },
        })
          .then((response: AxiosResponse<ClaimListResponse>) => {
            const data = response.data;
            if (data.totalCount === 0) {
              editDeal(event);
            } else {
              setRaisedClaimsResponse(data);
              setOpenEditModal(true);
            }
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.DELETE) {
        showLoading();
        get(api(Consts.Api.DealAgreementClaims.replace(':id', id)), {
          param: {
            ...claimPagination,
          },
        })
          .then((response: AxiosResponse<ClaimListResponse>) => {
            if (response.data.totalCount === 0) {
              setOpenDeleteConfirmModal(true);
            } else {
              if (response.data.totalRaisedCount > 0) {
                setOpenDeleteWithClaimForbiddenModal(true);
              } else {
                setOpenDeleteWithClaimConfirmModal(true);
              }
            }
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.REVERSE) {
        navigate(Consts.RouterPath.DealReversal.replace(':id', id));
      }
    },
    [claimPagination, editDeal, hideLoading, navigate, id, loggedInStaffCode, showLoading]
  );

  const headingButtons: HeadingButton[] = useMemo(() => {
    if (!dealData) {
      return [];
    }
    const buttons: HeadingButton[] = [
      ...(dealData.isReversible && dealReversalFeatureEnabled
        ? [
            {
              label: 'Reverse Deal',
              icon: <ReverseIcon style={{width: '1.3125rem'}} />,
              style: {color: '#626262', fontWeight: 400},
              onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleNavAction(Consts.SummaryPageActionTypes.REVERSE, event),
              role: Consts.UserRoleEnum.AddOrUpdateDeals,
              isLocked: isDealLocked(configs, dealData.endAt),
            },
          ]
        : []),
      {
        label: 'Duplicate Deal',
        icon: <DuplicateIcon style={{width: '1.3125rem'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction(Consts.SummaryPageActionTypes.DUPLICATE, event),
        role: Consts.UserRoleEnum.AddOrUpdateDeals,
      },
    ];
    if (!dealData.isDeleted) {
      buttons.push(
        {
          label: 'Edit Deal',
          icon: <EditIcon style={{width: '1.3125rem'}} />,
          style: {color: '#626262', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction(Consts.SummaryPageActionTypes.EDIT, event),
          role: Consts.UserRoleEnum.AddOrUpdateDeals,
          isLocked: isDealLocked(configs, dealData?.endAt),
        },
        {
          label: 'Delete Deal',
          icon: <DeleteIcon style={{width: '1.3125rem'}} />,
          style: {color: '#d0021b', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction(Consts.SummaryPageActionTypes.DELETE, event),
          role: Consts.UserRoleEnum.DeleteDeals,
          isLocked: isDealLocked(configs, dealData.endAt),
          disabled: !dealData.isDeletable,
          disabledText: 'Cannot be deleted as there are related invoiced and/or raised claim(s).',
        }
      );
    }
    return buttons;
  }, [dealData, handleNavAction, configs, dealReversalFeatureEnabled]);

  const setData = useCallback(
    (responseData: DealAgreementSummaryResponse) => {
      setDealData(responseData);
      setDealDetails([
        {
          title: 'Claim Vendor',
          value: responseData.claimVendorName,
        },
        {
          title: 'Department',
          value: responseData.departmentDescription,
        },
        {
          title: 'Deal Type',
          value: getDealType(responseData.dealValues.dealType) ?? '',
        },
        {
          title: 'Start Date',
          value: getFormattedDateTime(responseData.startAt),
        },
        {
          title: 'End Date',
          value: getFormattedDateTime(responseData.endAt),
        },
        {
          title: 'Owner',
          value: `${responseData.ownedByStaffCode} - ${responseData.ownedByStaffName}`,
        },
        {
          title: 'Last Modified Date',
          value: getFormattedDateTime(responseData.lastModifiedAt),
        },
        {
          title: 'Last Modified By',
          value: `${responseData.lastModifiedByStaffCode} - ${responseData.lastModifiedByStaffName}`,
        },
      ]);
      setRows(responseData.dealValues.data);
      setPagination({
        pageSize: responseData.dealValues.pageSize,
        currentPage: responseData.dealValues.currentPage,
        totalCount: responseData.dealValues.totalCount,
        totalPages: responseData.dealValues.totalPages,
      });
      hideLoading();
    },
    [hideLoading]
  );

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.DealAgreementSummary.replace(':id', id)))
      .then(({data}: AxiosResponse<DealAgreementSummaryResponse>) => {
        setData(data);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [hideLoading, id, setData, showLoading]);

  const loadDealValues = useCallback(
    (dealId: string, paging: Partial<Pagination>) => {
      showLoading();
      get(api(Consts.Api.DealAgreementSummaryValues.replace(':id', dealId)), {params: paging || {}})
        .then((response: AxiosResponse<DealValuesForDealSummaryResponse>) => {
          if (response.data.data) {
            setRows(response.data.data);
            setPagination({
              pageSize: response.data.pageSize,
              currentPage: response.data.currentPage,
              totalCount: response.data.totalCount,
              totalPages: response.data.totalPages,
            });
          } else {
            setPagination(Consts.DefaultPagination);
            setRows([]);
          }

          alertService.clear(defaultAlertId);
        })
        .catch((error) => {
          alertService.alert({
            id: defaultAlertId,
            ...{message: 'Failed to load deal values', response: error.response},
          });
        })
        .finally(() => {
          hideLoading();
        });
    },
    [showLoading, hideLoading]
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const downloadDealValues = () => {
    showLoading();
    get(api(Consts.Api.DealValuesDownload.replace(':id', id)), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = 'DealValues.csv';
        link.click();
        link.remove();
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const downloadSummaryReport = () => {
    showLoading();
    get(api(Consts.Api.SummaryReportDownload.replace(':id', id)), {
      responseType: 'blob',
    })
      .then((response) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([response.data]));
        link.href = url;
        link.download = response.request
          .getResponseHeader('Content-Disposition')
          .split('filename="')[1]
          .split('";')[0];
        link.click();
        link.remove();
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  function uploadComplete(response: AxiosResponse<AttachmentUploadResponse[]>) {
    setAttachments((prevAttachments) => prevAttachments.concat(response.data));
  }
  function deleteAttachment(attachmentId: string) {
    showLoading();
    del(api(Consts.Api.DealAttachment).replace(':dealId', id).replace(':id', attachmentId))
      .then(() => {
        setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }
  function downloadAttachment(attachment: AttachmentType) {
    showLoading();
    get(api(Consts.Api.DealAttachment).replace(':dealId', id).replace(':id', attachment.id), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = attachment.fileName;
        link.click();
        link.remove();
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }

  const claimFrequencyLabel = useMemo(() => {
    if (!dealData?.claimInterval) {
      return null;
    }
    return dealData?.claimInterval === 'DaysAfterExpiry'
      ? Consts.DealClaimInterval.find(
          (interval) => interval.value === dealData.claimInterval
        )?.label.replace('Set', `${dealData?.claimDaysAfterExpiry ?? ''}`)
      : Consts.DealClaimInterval.find((interval) => interval.value === dealData.claimInterval)
          ?.label;
  }, [dealData]);

  function handleUpdateSANSuccess(supplierApprovalNumber?: string) {
    setDealData(
      (prevData) =>
        ({
          ...(prevData ?? {}),
          supplierApprovalNumber,
        } as DealAgreementSummaryResponse)
    );
  }

  function handleUpdateSANError(error: AxiosError) {
    alertService.alert({
      ...{message: error.message, response: error.response},
      id: defaultAlertId,
    });
  }

  return (
    <Root className={classes.root}>
      {dealData ? (
        <div className={classes.pageFlexColumn}>
          <Container maxWidth={false}>
            {dealData.isDeleted ? (
              <ErrorBox type={AlertType.Info}>
                This deal is deleted - it will be removed overnight
              </ErrorBox>
            ) : null}
            <HeadingComponent
              headingIcon={<CreateDealIcon />}
              headingLabel="Deal"
              buttons={headingButtons}
              style={dealData.isDeleted ? {paddingTop: '0px'} : {}}
            />

            <Paper className={classes.contentWrapper}>
              <SummaryDetailsComponent
                id={dealData.id}
                details={dealDetails}
                status={dealData.status}
                desc={dealData.description}
                staffCode={dealData.ownedByStaffCode}
                staffName={dealData.ownedByStaffName}
                generatePricebookUpdate={dealData?.generatePricebookUpdate}
                isPromo={isPromo}
                type="Deal"
                hideOwner
              />

              <div className={classes.fixWidthContainerSm}>
                <SplitIconAmountComponent
                  icon={<MoneyGraphIcon />}
                  label="Total Amount Accrued"
                  amount={dealData.amount}
                  taxAmount={dealData.accrualTaxAmount ?? 0}
                  gstType={dealData.gstType}
                />
              </div>
            </Paper>
          </Container>

          <div>
            <TabsComponent
              tabs={Tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />

            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.DealInformation}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <Paper className={classes.tabPanelContentWrapper}>
                  <div className={classes.flexContent}>
                    <DynamicContainerWithHeader title="Finance Details" style={{width: '50%'}}>
                      <SplitTextComponent
                        flexibleHeight
                        leftLabel="Finance Accounts"
                        rightLabel={
                          <FinanceAccountsSummary financeAccounts={dealData.financeAccounts} />
                        }
                      />
                      <SplitTextComponent
                        leftLabel="Claim Frequency"
                        rightLabel={claimFrequencyLabel}
                      />
                      <SplitTextComponent
                        leftLabel="Returns"
                        rightLabel={dealData.excludeReturns ? 'Excluded' : 'Included'}
                      />
                      <SplitTextComponent
                        leftLabel="Supplier Approval Number"
                        rightLabelFullWidth
                        rightLabel={
                          <SupplierApprovalNumberEditable
                            dealData={dealData}
                            onSave={handleUpdateSANSuccess}
                            onError={handleUpdateSANError}
                            direction="row"
                          />
                        }
                      />
                    </DynamicContainerWithHeader>
                    <DynamicContainerWithHeader title="Location" style={{width: '45%'}}>
                      <SplitTextComponent
                        leftLabel={
                          dealData.locationCriteria
                            ? `${dealData.locationCriteria.resultCount} locations`
                            : 'All Locations'
                        }
                        buttonLabel="View Locations"
                        icon={<LocationsIcon />}
                        buttonIcon={<ChevronRightIcon />}
                        style={{color: '#DA6A00'}}
                        onButtonClick={() => setLocationSelectorOpen(true)}
                      />
                    </DynamicContainerWithHeader>
                  </div>
                </Paper>

                <div className={classes.flexContentCentered}>
                  <Typography variant="h5">Deal Values</Typography>
                  <ButtonsContainer>
                    {dealData.dealValues.dealType === Consts.StandardDealTypeEnum.Purchases && (
                      <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                        Download Purchases Summary Report
                      </SummaryReportDownloadButton>
                    )}

                    {dealData.dealValues.dealType === Consts.StandardDealTypeEnum.Sales && (
                      <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                        Download Written Scanbacks Summary Report
                      </SummaryReportDownloadButton>
                    )}

                    <DownloadButton onClick={downloadDealValues}>
                      Download CSV Report
                    </DownloadButton>
                  </ButtonsContainer>
                </div>
                <div className={classes.dealValuesTable}>
                  <DealSummaryValuesTable
                    values={rows}
                    skuSelectorMode={rows.every((value) => !!value.productCode)}
                    dealType={dealData.dealValues.dealType}
                    pagination={pagination}
                    onPagination={(currentPage, pageSize) => {
                      loadDealValues(id, {currentPage, pageSize});
                    }}
                  />
                </div>
              </Container>
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Attachments}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <Paper className={classes.tabPanelContentWrapper}>
                  <FileUploaderRoot>
                    <FilesUploader
                      maxSizeInMb={20}
                      multiple={true}
                      apiEndpoint={api(Consts.Api.DealAttachments.replace(':dealId', id))}
                      accept={{
                        'image/png': [],
                        'image/jpeg': [],
                        'image/jpg': [],
                        'application/pdf': [],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                          [], // for docx
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
                        'message/rfc822': ['.eml'], // for eml
                        'text/csv': ['.csv'], // for csv
                        'application/vnd.ms-outlook': ['.msg'], // for msg
                      }}
                      title="There are no attachments in this deal yet."
                      subTitle="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
                      onComplete={uploadComplete}
                      noFiles={attachments.length === 0}
                      showHeadingButton={attachments.length !== 0}
                      showBottomButton={false}
                      button={UploadButton}
                      buttonLabel="Add your first attachment"
                      continueAddButtonLabel="Add New Attachment"
                      heading="Attachments"
                    >
                      <div>
                        {attachments.map((attachment: AttachmentType, index: number) => (
                          <Attachment
                            key={index}
                            fileName={attachment.fileName}
                            sx={{padding: '0.625rem 1.875rem'}}
                          >
                            <DownloadActionButton
                              style={{
                                marginRight: canDeleteAttachment ? '1rem' : '0',
                                color: 'green',
                              }}
                              onClick={() => {
                                downloadAttachment(attachment);
                              }}
                            ></DownloadActionButton>
                            {canDeleteAttachment && (
                              <DeleteActionButton
                                onClick={() => {
                                  deleteAttachment(attachment.id);
                                }}
                              ></DeleteActionButton>
                            )}
                          </Attachment>
                        ))}
                      </div>
                    </FilesUploader>
                  </FileUploaderRoot>
                </Paper>
              </Container>
            </TableTabPanel>
          </div>
          {locationSelectorOpen ? (
            <LocationSelector
              fullScreen
              locationCriteria={dealData.locationCriteria}
              open={locationSelectorOpen}
              handleClose={() => setLocationSelectorOpen(false)}
              readOnly
            />
          ) : null}
          <DeleteAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteConfirmModal}
            onCancel={() => setOpenDeleteConfirmModal(false)}
            onOk={() => {
              setOpenDeleteConfirmModal(false);
              deleteDeal();
            }}
          />
          <DeleteAgreementOrRebateWithActiveClaimConfirmModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteWithClaimConfirmModal}
            onCancel={() => setOpenDeleteWithClaimConfirmModal(false)}
            onOk={() => {
              setOpenDeleteWithClaimConfirmModal(false);
              setOpenDeleteConfirmModal(true);
            }}
          />
          <DeleteAgreementorRebateWithClaimForbiddenModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteWithClaimForbiddenModal}
            onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
          />
          <EditAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Deal}
            referenceId={id}
            ctrlKey={ctrlKey}
            open={openEditModal}
            claimsResponse={raisedClaimsResponse}
            onOk={() => {
              setOpenEditModal(false);
            }}
            onCancel={() => setOpenEditModal(false)}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default DealSummary;
