import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';
import {isNullish} from '../../utils';
import {InitialDealReversalFormValues, DealReversalFormValues} from '../../types';
import StepperForm from '../Form/StepperForm';
import {stepDealReversalDetailsValidationSchema} from './validationSchema';
import StepDealReversalDetails from './StepDealReversalDetails';
import StepDealReversalAttachments from './StepDealReversalAttachments';

const formSteps = ['Reversal Details', 'Attachments'];

const breakPoint = 1840;
const StyledStepperForm = styled(StepperForm)(({theme}) => ({
  '&.PageContentContainer-root': {
    [theme.breakpoints.up(breakPoint)]: {
      width: '94rem',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    minWidth: '80rem',
    width: 'auto',
    display: 'inline-block',
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    marginLeft: '2.25rem',
    marginRight: '2.25rem',
  },
}));

type Props = {
  title: string;
  initialValues: InitialDealReversalFormValues;
  indexOverride?: number;
};

const DealReversalForm: FC<Props> = ({title, initialValues, indexOverride}) => {
  const navigate = useNavigate();

  const getStepContent = (
    index: number,
    handleBack: (values: DealReversalFormValues) => void,
    handleNext: (values: DealReversalFormValues) => void
  ) => {
    const contentIndex = indexOverride ?? index;
    switch (contentIndex) {
      case 0:
        return (
          <StepDealReversalDetails
            step={contentIndex + 1}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepDealReversalDetailsValidationSchema}
          />
        );
      case 1:
        return (
          <StepDealReversalAttachments
            step={contentIndex + 1}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
          />
        );
      default:
        return null;
    }
  };

  const handleAttachmentsStepSubmit = async (values: DealReversalFormValues) => {
    if (isNullish(values.newDealId)) {
      // reversal only
      navigate(Consts.RouterPath.DealSummary.replace(':id', values.reversalDealId));
    } else {
      // reversal and new deal
      navigate(Consts.RouterPath.EditDeal.replace(':id', values.newDealId));
    }
  };

  return (
    <StyledStepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={handleAttachmentsStepSubmit}
      steps={formSteps}
      getStepContent={getStepContent}
      indexOverride={indexOverride}
    />
  );
};

export default DealReversalForm;
